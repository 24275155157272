import {bindable, customElement, inject, noView} from "aurelia-framework";
import {render, unmountComponentAtNode} from "react-dom";
import React from "react";
import ContingentDisplay from "./contingentDisplay";

@noView
@inject(Element)
@customElement("contingent-display-aurelia")
export default class ContingentDisplayAurelia {
    @bindable() dates;
    @bindable() rows;
    @bindable() netContingent;

    client = null;

    constructor(container) {
        this.container = container;
    }

    attached() {
        this.render();
    }

    bind() {

    }

    datesChanged() {
        this.detached();

        this.attached();
    }

    rowsChanged() {
        this.detached();

        this.attached();
    }

    netContingentChanged() {
        this.detached();

        this.attached();
    }

    async render() {
        render(<ContingentDisplay
            dates={this.dates}
            rows={this.rows}
            netContingent={this.netContingent}/>, this.container);
    }

    detached() {
        unmountComponentAtNode(this.container);
    }
}
