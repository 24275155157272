import {bindable, customElement, inject} from 'aurelia-framework';
import {ModelLabelService} from './model-label-service';
import * as _ from 'lodash';

/**
 * Class to display an object or a list of objects as a reference with a link
 */
@customElement('reference-display')
@inject(ModelLabelService)
export class ReferenceDisplay {
    @bindable reference;
    @bindable multiModel = false;
    @bindable separator = ',';
    @bindable renderAsLink = true;
    @bindable labelProperty;

    links = [];

    constructor(modelLabelService) {
        this.modelLabelService = modelLabelService;
    }

    bind() {
        this.referenceChanged();
    }

    referenceChanged() {
        this.links = [];

        if (!this.reference) {
            return;
        }

        let references = this.reference;

        if (!Array.isArray(references)) {
            references = [references];
        }

        references = references.map((reference) => {
            return this.modelLabelService.getReferenceLinks(reference, this.multiModel, this.labelProperty);
        });

        Promise.all(references).then((links) => {

            this.links = [];

            links.forEach(link => {

                if (link) {
                    this.links.push(link);
                }

            });

        });
    }
}
