import React, {useRef} from "react";
import {Icon, Popover, Whisper} from "rsuite";

export const ContingentDisplay = ({dates, rows, netContingent}) => {

    const getBackground = (cell) => {
        let used = !netContingent ? cell.grossUsed : cell.netUsed;

        if (used > cell.contingent) {
            return {backgroundColor: 'red', color: 'white'};
        } else if (used > cell.contingent * 0.7) {
            return {backgroundColor: 'orange', color: 'white'};
        } else {
            return {backgroundColor: 'green', color: 'white'};
        }
    }

    return (
        <table className="table table-sm table-bordered contingent-table">

            <thead>
            <tr>
                <th></th>
                { dates.map((date) => {
                    return <th>
                        {date}
                    </th>
                }) }
            </tr>
            </thead>
            <tbody>
                {rows.map((row, index) => {

                    return <tr>
                        { row.map((cell, index) => {
                            if (index === 0) {
                                return <td>
                                    {cell}
                                </td>;
                            }

                            return <td className="contingent-cell" style={getBackground(cell)}>

                                <Whisper trigger="hover"
                                         speaker={
                                             <Popover>
                                                 <div>
                                                     <span>Kontingent: { cell.contingent}</span><br />
                                                 { cell.refs.map((ref) => {
                                                     return <span>
                                                         {ref[1]}: {ref[3]}
                                                     </span>;
                                                 }) }
                                                 </div>
                                             </Popover>
                                         }>
                                    <span>{ !netContingent ? cell.contingent - cell.grossUsed : cell.contingent - cell.netUsed }</span>
                                </Whisper>
                            </td>;
                        }) }
                    </tr>

                })}
            </tbody>
        </table>
    );

};

export default ContingentDisplay;
