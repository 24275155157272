import {DialogController} from 'aurelia-dialog';
import {inject} from "aurelia-framework";
import {DimensionConfig} from "./dimension-config";

@inject(DialogController, DimensionConfig)
export class ChangeSeasonDialog {

    changeType = null;
    changeTypes = [
        {
            value: 'year',
            label: 'sio.field.year',
        },
        {
            value: 'day',
            label: 'sio.field.day',
        }
    ];
    changeTypeConfig = {
        label: 'price.changeType',
    };
    yearConfig = {
        label: 'price.year'
    };
    dayConfig = {
        label: 'price.day'
    };

    factor = null;
    money = null;

    constructor(dialogController, dimensionConfig) {
        this.dialogController = dialogController;
        this.dimensionConfig = dimensionConfig;
    }

    setChangeType(changeType) {
        this.changeType = changeType;
    }

    activate(context) {
        Object.assign(this, context);
    }

    done() {

        this.dialogController.ok({
            day: this.day,
            year: this.year
        });

        return Promise.resolve();
    }
}
