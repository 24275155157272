import {DialogController} from 'aurelia-dialog';
import {inject} from "aurelia-framework";
import {DimensionConfig} from "./dimension-config";

@inject(DialogController, DimensionConfig)
export class EditDimensionsDialog {

    dimensions = [];

    constructor(dialogController, dimensionConfig) {
        this.dialogController = dialogController;
        this.dimensionConfig = dimensionConfig;
    }

    activate(context) {
        Object.assign(this, context);
    }

    getAvailableDimensions() {
        let availableDimensions = [];

        for (let dimensionKey of this.dimensionConfig.keys()) {

            if (this.dimensionConfig.isVisible(dimensionKey, this.context, this.contingent)) {
                availableDimensions.push(dimensionKey);
            }
        }

        return availableDimensions;
    }

    done() {

        this.dialogController.ok({
            dimensions: this.dimensions
        });

        return Promise.resolve();
    }

    remove(dimensionKey, index) {
        this.dimensions[dimensionKey].splice(index, 1);
    }

    add(dimensionKey) {
        if (!this.dimensions[dimensionKey]) {
            this.dimensions[dimensionKey] = [];
        }

        this.dimensions[dimensionKey].push({});
    }
}
