import {bindable, customElement, inject} from 'aurelia-framework';
import moment from "moment-timezone";
import {MilitaryTimeValueConverter} from "../value-converter/military-time-value-converter";
import {currencies} from "../currency/model/currencies.model";

@inject(MilitaryTimeValueConverter)
export class DimensionConfig {

    militaryTime;

    weekDayMap = {
        1: 'Mo',
        2: 'Di',
        3: 'Mi',
        4: 'Do',
        5: 'Fr',
        6: 'Sa',
        7: 'So'
    };

    dimensionConfigs = {
        roomCategory: {
            label: 'Zimmerkategorie',
            column: 'col-xs-4',
            hasDimension: ({roomCategory}) => {
                return roomCategory != null;
            },
            getDimension: ({roomCategory}) => {
                return [roomCategory.id, {roomCategory}];
            },
            show: (context) => {
                return context?.modelId === 'tourism-accommodation/accommodation';
            },
            pick: ['roomCategory'],
            valueLabel: (dimensionValue) => {
                return '<reference-display reference.one-time="line.roomCategory"></reference-display>';
            },
            compare: (dimensionValue, line) => {
                return dimensionValue?.roomCategory?.id == line?.roomCategory?.id;
            }
        },
        cabineCategory: {
            label: 'Kabinenkategorie',
            column: 'col-xs-4',
            hasDimension: ({cabineCategory}) => {
                return cabineCategory != null;
            },
            getDimension: ({cabineCategory}) => {
                return [cabineCategory.id, {cabineCategory}];
            },
            show: (context) => {
                return context?.modelId === 'tourism-ship/ship' || context?.modelId === 'tourism-ferry/ferry';
            },
            pick: ['cabineCategory'],
            valueLabel: (dimensionValue) => {
                return '<reference-display reference.one-time="line.cabineCategory"></reference-display>';
            },
            compare: (dimensionValue, line) => {
                return dimensionValue?.cabineCategory?.id == line?.cabineCategory?.id;
            }
        },
        fare: {
            label: 'Verpflegung',
            column: 'col-xs-4',
            hasDimension: ({fare}) => {
                return fare != null;
            },
            getDimension: ({fare}) => {
                return [fare.id, {fare}];
            },
            show: (context) => {
                return context?.modelId === 'tourism-accommodation/accommodation' || context?.modelId === 'tourism-ship/ship' || context?.modelId === 'tourism-ferry/ferry';
            },
            pick: ['fare'],
            valueLabel: (dimensionValue) => {
                return '<reference-display reference.one-time="line.fare"></reference-display>';
            },
            compare: (dimensionValue, line) => {
                return dimensionValue?.fare?.id == line?.fare?.id;
            }
        },
        tariff: {
            label: 'Tarif',
            column: 'col-xs-4',
            hasDimension: ({tariff}) => {
                return tariff != null;
            },
            getDimension: ({tariff}) => {
                return [tariff.id, {tariff}];
            },
            show: (context) => {
                return context?.modelId === 'tourism-ship/ship' || context?.modelId === 'tourism-ferry/ferry';
            },
            pick: ['tariff'],
            valueLabel: (dimensionValue) => {
                return '<reference-display reference.one-time="line.tariff"></reference-display>';
            },
            compare: (dimensionValue, line) => {
                return dimensionValue?.tariff?.id == line?.tariff?.id;
            }
        },
        serviceTariff: {
            label: 'Tarif',
            column: 'col-xs-4',
            hasDimension: ({serviceTariff}) => {
                return serviceTariff != null;
            },
            getDimension: ({serviceTariff}) => {
                return [serviceTariff.id, {serviceTariff}];
            },
            show: (context) => {
                return context?.modelId === 'tourism-service/service';
            },
            pick: ['serviceTariff'],
            valueLabel: (dimensionValue) => {
                return '<reference-display reference.one-time="line.serviceTariff"></reference-display>';
            },
            compare: (dimensionValue, line) => {
                return dimensionValue?.serviceTariff?.id == line?.serviceTariff?.id;
            }
        },
        date: {
            label: 'Datum',
            column: 'col-xs-4',
            clearfix: true,
            help: 'Geben Sie mehrere Zeilen an, um Preise für einen gewissen Zeitraum festzulegen. Saison ist dabei optional. Geben Sie die gleiche Saison an, um den Preis für mehrere Zeiträume auf einmal einzugeben.',
            hasDimension: ({from, to}) => {
                return from != null && to != null;
            },
            getDimension: ({from, to, seasonText}) => {
                return [from + '-' + to, {from, to, seasonText}];
            },
            postProcess: (seasons) => {
                let groupedSeasons = [];

                for (let season of seasons) {

                    if (season.seasonText) {
                        if (!groupedSeasons[season.seasonText]) {
                            groupedSeasons[season.seasonText] = {seasonText: season.seasonText, dates: []};
                        }

                        groupedSeasons[season.seasonText].dates.push({from: season.from, to: season.to});
                    } else {
                        groupedSeasons.push({dates: [{from: season.from, to: season.to}]});
                    }
                }

                return Object.values(groupedSeasons);
            },
            pick: ['dates', 'seasonText'],
            valueLabel: (dimensionValue) => {
                let label = [];

                for (let date of dimensionValue.dates) {
                    label.push(moment(date.from).format('L') + ' - ' + moment(date.to).format('L'));
                }

                if (dimensionValue.seasonText != null) {
                    label.push('Saison: ' + dimensionValue.seasonText);
                }

                return label.join('<br>');
            },
            compare: (dimensionValue, line) => {

                let compareKeyFrom = line?.dates?.[0].from ?? line.from;
                let compareKeyTo = line?.dates?.[0].to ?? line.to;

                return dimensionValue.dates[0].from == compareKeyFrom
                    && dimensionValue.dates[0].to === compareKeyTo
                    && dimensionValue.seasonText == line.seasonText;
            }
        },
        count: {
            label: 'PAX',
            column: 'col-xs-4',
            hasDimension: ({countFrom, countTo}) => {
                return countFrom != null && countTo != null;
            },
            getDimension: ({countFrom, countTo}) => {
                return [countFrom + '-' + countTo, {countFrom, countTo}];
            },
            pick: ['countFrom', 'countTo'],
            valueLabel: (dimensionValue) => {
                return dimensionValue.countFrom + ' - ' + dimensionValue.countTo;
            },
            compare: (dimensionValue, line) => {
                return dimensionValue.countFrom == line.countFrom && dimensionValue.countTo == line.countTo;
            }
        },
        duration: {
            label: 'Dauer',
            detailLabel: (context) => {
                return 'Dauer in Tagen / Nächten je nach Preis-Modus';
            },
            help: 'Je nach Preis-Modus handelt es sich hier um Nächte oder Tage. Bei Hotels ist der Standard auf Nächte eingestellt.',
            column: 'col-xs-4',
            hasDimension: ({durationFrom, durationTo}) => {
                return durationFrom != null && durationTo != null;
            },
            getDimension: ({durationFrom, durationTo}) => {
                return [durationFrom + '-' + durationTo, {durationFrom, durationTo}];
            },
            show: (context) => {
                return context?.modelId === 'tourism-accommodation/accommodation' || context?.modelId === 'tourism-ship/ship' || context?.modelId === 'tourism-ferry/ferry';
            },
            pick: ['durationFrom', 'durationTo'],
            valueLabel: (dimensionValue) => {
                return dimensionValue.durationFrom + ' - ' + dimensionValue.durationTo;
            },
            compare: (dimensionValue, line) => {
                return dimensionValue.durationFrom == line.durationFrom && dimensionValue.durationTo == line.durationTo;
            }
        },
        weekDays: {
            label: 'Wochentage',
            column: 'col-xs-4',
            clearfix: true,
            hasDimension: ({weekDays}) => {
                return weekDays && weekDays.length > 0;
            },
            getDimension: ({weekDays}) => {
                return [weekDays.join('-'), {weekDays}];
            },
            pick: ['weekDays'],
            valueLabel: (dimensionValue) => {
                return dimensionValue.weekDays.map(weekDay => this.weekDayMap[weekDay]).join(', ');
            },
            compare: (dimensionValue, line) => {
                return dimensionValue?.weekDays?.join('-') == line?.weekDays?.join('-');
            }
        },
        time: {
            label: 'Zeit',
            detailLabel: (context) => {
                return 'Leistungsbeginn (bis optional)';
            },
            help: 'Geben Sie mehrere Zeilen an, um Preise für einen gewisse Uhrzeit festzulegen. Sie können das Bis freilassen, dann zählt der Preis nur für die spezifische Uhrzeit. Preise werden anhand des Beginns der Leistung ermittelt. Saison ist dabei optional. Geben Sie die gleiche Saison an, um den Preis für mehrere Zeiträume auf einmal einzugeben.',
            column: 'col-xs-4',
            hasDimension: ({timeFrom, timeTo}) => {
                return timeFrom != null;
            },
            getDimension: ({timeFrom, timeTo, timeText}) => {
                return [timeFrom + '-' + timeTo, {timeFrom, timeTo, timeText}];
            },
            postProcess: (times) => {
                let groupedTimes = [];

                for (let time of times) {

                    if (time.timeText) {
                        if (!groupedTimes[time.timeText]) {
                            groupedTimes[time.timeText] = {timeText: time.timeText, times: []};
                        }

                        groupedTimes[time.timeText].times.push({timeFrom: time.timeFrom, timeTo: time.timeTo});
                    } else {
                        groupedTimes.push({times: [{timeFrom: time.timeFrom, timeTo: time.timeTo}]});
                    }
                }

                return Object.values(groupedTimes);
            },
            show: (context) => {
                return context?.modelId === 'tourism-service/service' || context?.modelId === 'tourism-transfer/transfer' || context?.modelId === 'tourism-ferry/ferry';
            },
            pick: ['times', 'timeText'],
            valueLabel: (dimensionValue) => {
                let label = [];

                for (let time of dimensionValue.times) {
                    label.push(this.militaryTime.toView(time.timeFrom) + (time.timeTo ? (' - ' + this.militaryTime.toView(time.timeTo)) : ''));
                }

                if (dimensionValue.timeText != null) {
                    label.push('Beschreibung: ' + dimensionValue.timeText);
                }

                return label.join('<br>');
            },
            compare: (dimensionValue, line) => {

                let compareKeyFrom = line?.times?.[0].timeFrom ?? line.timeFrom;
                let compareKeyTo = line?.times?.[0].timeTo ?? line.timeTo;

                return dimensionValue.times[0].timeFrom == compareKeyFrom
                    && dimensionValue.times[0].timeTo == compareKeyTo
                    && dimensionValue.timeText == line.timeText;
            }
        },
    }

    constructor(militaryTime) {
        this.militaryTime = militaryTime;
    }

    _convertToView(amount, currency = 'EUR') {
        return amount / Math.pow(10, currencies[currency].decimal_digits);
    }

    _convertToModel(amount, currency = 'EUR') {
        return Math.round(amount * Math.pow(10, currencies[currency].decimal_digits));
    }

    keys() {
        return Object.keys(this.dimensionConfigs);
    }

    get(dimensionKey) {
        return this.dimensionConfigs[dimensionKey];
    }

    isVisible(dimensionKey, context, contingent) {
        let config = this.dimensionConfigs[dimensionKey];

        if (contingent && dimensionKey === 'time') {
            return false;
        }

        return !config.show || config.show(context);
    }

    getKey(dimensionTypes, dimensions, line) {

        let completeIndex = [];

        for (let i = 0; i < dimensions.length; i++) {

            let dimensionValues = dimensions[i];
            let dimensionTypeValue = dimensionTypes[i].type;

            for (let j = 0; j < dimensionValues.length; j++) {

                let dimensionValue = dimensionValues[j];

                if (this.dimensionConfigs[dimensionTypeValue].compare(dimensionValue, line)) {
                    completeIndex.push(j);
                    break;
                }
            }
        }

        return completeIndex.join('-');
    }
}
