import {bindable, customElement, inject} from 'aurelia-framework';
import {FormServiceFactory} from '../form/service/form-service-factory';
import {currencies} from "../currency/model/currencies.model";
import {UserClient} from "../api/user-client";
import * as _ from "lodash";
import Sortable from "sortablejs";

@customElement('price-set-input')
@inject(FormServiceFactory, UserClient)
export class PriceSetInput {

    constructor(formServiceFactory, user) {
        this.formServiceFactory = formServiceFactory;

        const settings = user.getUser().organization.settings.priceSettings;

        this.settingsActiveTabs = settings.tabs || ['single', 'date', 'dateAndCount', 'dateAndWeekDay', 'count'];
        this.enterPurchasePrices = !!settings.defaultPurchasePrice;
        this.showRetailPricesSetting = !(settings.defaultPurchasePrice || false);
        this.showManualFactorFieldSetting = !(settings.hideManualFactor || false);
        this.seasonTexts = settings.predefinedSeasonTexts ?? [];
    }

    @bindable field;
    @bindable({defaultBindingMode: 2}) value;

    formConfig = {
        template: '',
        fields: [
            {
                type: 'money',
                property: 'purchasePrice',
                label: 'sio.field.purchasePrice',
                required: false
            },
            {
                type: 'money',
                property: 'retailPrice',
                label: 'sio.field.retailPrice',
                required: false
            },
        ]
    };

    currencyConfig = {
        label: 'sio.field.currency',
        required: true,
        set: 'currency',
        translateChoiceLabel: false,
    };

    taxPercentageConfig = {
        label: 'sio.field.taxPercentage',
        required: true,
        set: 'taxType',
    };

    manualFactorConfig = {
        label: 'price.manualFactor',
        help: 'price.manualFactor-help'
    };

    taxPercentage = 'DE-no_rate';
    pricePerCount = false;
    type = 'single';
    currency = 'EUR';
    priceLines = [{prices: []}];
    priceLinesDateAndCount = [{prices: []}];
    counts = [{}];

    static _convertToView(amount, currency = 'EUR') {
        return amount / Math.pow(10, currencies[currency].decimal_digits);
    }

    _getControlUID() {
        return 'price-set-input-' + this.field.property;
    }

    valueChanged(newValue) {

        //Important check here, as a view change also triggers this callback and this way we would have a loop
        if (this.value === newValue) {
            return;
        }

        this._processModelValue();
    }

    static _convertToModel(amount, currency = 'EUR') {
        return Math.round(amount * Math.pow(10, currencies[currency].decimal_digits));
    }

    bind() {
        this.type = this.settingsActiveTabs[0];
        this.formService = this.formServiceFactory.getFormService(this.formConfig, {});
        this.formService.changeCallback = this._calculateValue.bind(this);
        this.showRetailPrices = this.field.options?.showRetailPrices ?? this.showRetailPricesSetting;
        this.showManualFactorField = this.field.options?.showManualFactorField ?? this.showManualFactorFieldSetting;

        this._processModelValue();
    }

    _processModelValue() {
        //Check if it's a database value
        if (!this.value) {
            return;
        }

        this.type = this.value.type;
        this.pricePerCount = this.value.pricePerCount;

        // make sure that form can be displayed
        if (!this.settingsActiveTabs.includes(this.type)) {
            this.settingsActiveTabs.push(this.type);
        }

        const columns = new Map();
        const priceLines = new Map;

        switch (this.type) {

            case 'dateAndCount':
                for (let {countFrom, countTo} of this.value.priceLines) {

                    if (!countFrom || !countTo) {
                        continue;
                    }

                    const key = countFrom + '-' + countTo;

                    if (!columns.has(key)) {
                        columns.set(key, {min: countFrom, max: countTo});
                    }
                }

                this.counts = Array.from(columns.values()).sort((a, b) => a.min - b.min);

                for (let priceLine of this.value.priceLines) {

                    if (!priceLine.countFrom || !priceLine.countTo) {
                        continue;
                    }

                    const index = this._findMatchingColumn(priceLine.countFrom, priceLine.countTo);

                    if (null == index) {
                        continue;
                    }

                    let price = null;

                    if (this.value.fixed === 'purchasePrice' && priceLine.purchasePrice) {
                        this.currency = priceLine.purchasePrice.currency;
                        price = PriceSetInput._convertToView(priceLine.purchasePrice.amount, priceLine.purchasePrice.currency);
                    } else if (this.value.fixed === 'retailPrice' && priceLine.retailPrice) {
                        this.currency = priceLine.retailPrice.currency;
                        price = PriceSetInput._convertToView(priceLine.retailPrice.amount, priceLine.retailPrice.currency);
                    } else {
                        price = 0.0;
                    }

                    const key = priceLine.from + '-' + priceLine.to;
                    const value = priceLines.get(key) ?? {
                        from: priceLine.from,
                        to: priceLine.to,
                        prices: [],
                        vacancies: priceLine.vacancies,
                        seasonText: priceLine.seasonText
                    };

                    if (price) {
                        value.prices[index] = price;
                    }

                    priceLines.set(key, value);
                }

                this.priceLinesDateAndCount = Array.from(priceLines.values());
                break;

            case 'count':
                for (let {countFrom, countTo, purchasePrice, retailPrice, vacancies} of this.value.priceLines) {

                    if (!countFrom || !countTo) {
                        continue;
                    }

                    const key = countFrom + '-' + countTo;

                    if (!columns.has(key)) {
                        const column = {min: countFrom, max: countTo};

                        if (this.value.fixed === 'purchasePrice' && purchasePrice) {
                            this.currency = purchasePrice.currency;
                            column.price = PriceSetInput._convertToView(purchasePrice.amount, purchasePrice.currency);
                        } else if (this.value.fixed === 'retailPrice' && retailPrice) {
                            this.currency = retailPrice.currency;
                            column.price = PriceSetInput._convertToView(retailPrice.amount, retailPrice.currency);
                        } else {
                            column.price = 0;
                        }

                        columns.set(key, column);
                        this.vacancies = vacancies;
                    }
                }


                this.counts = Array.from(columns.values()).sort((a, b) => a.min - b.min);
                this.priceLinesDateAndCount = Array.from(columns.values()).map(c => Object.assign({prices: []}, c));
                break;

            case 'date':
            case 'dateAndWeekDay':
                this.priceLines = this.value.priceLines.map(
                    ({from, to, purchasePrice, retailPrice, weekDays, seasonText}) => {
                        const value = {from, to, weekDays, seasonText};

                        if (this.value.fixed === 'purchasePrice' && purchasePrice) {
                            this.currency = purchasePrice.currency;
                            value.price = PriceSetInput._convertToView(purchasePrice.amount, purchasePrice.currency);
                        } else if (retailPrice) {
                            this.currency = retailPrice.currency;
                            value.price = PriceSetInput._convertToView(retailPrice.amount, retailPrice.currency);
                        }

                        return value;
                    });
                break;

            default:
                if (this.value.fixed === 'both' || this.value.fixed === 'purchasePrice') {
                    this.formService
                        .getFieldByProperty('purchasePrice')
                        .setValue(this.value.purchasePrice);
                }

                if (this.value.fixed === 'both' || this.value.fixed === 'retailPrice') {
                    this.formService
                        .getFieldByProperty('retailPrice')
                        .setValue(this.value.retailPrice);
                }
        }

        if (this.type !== 'single') {
            this.enterPurchasePrices = this.value.fixed === 'purchasePrice';
            this.manualFactor = this.value.manualFactor;
        }

        this.taxPercentage = this.value.taxPercentage || 0.0;

        if (this.value.modelId) {
            this._calculateValue();
        }
    }

    attached() {
        this.initializeSorting();
    }

    initializeSorting() {
        let element = document.getElementById(this._getControlUID());

        if (!element) {
            return;
        }

        this.sortable = Sortable.create(element, {
            handle: '.glyphicon-menu-hamburger',
            forceFallback: true,
            onEnd: this.onEnd.bind(this)
        });
    }

    switchTab(type) {

        //this.pricePerCount = true;
        this.type = type;

        this._calculateValue();

        this.initializeSorting();
    }

    onEnd(evt) {
        if (this.type === 'date' || this.type === 'dateAndWeekDay') {
            this.priceLines.move(evt.oldIndex, evt.newIndex);
        } else {
            this.priceLinesDateAndCount.move(evt.oldIndex, evt.newIndex);
        }

        this._calculateValue();
    }

    addCount() {
        this.counts.push({prices: []});

        this._calculateValue();
    }

    removeCount(index) {
        this.counts.splice(index, 1);

        if ('dateAndCount' === this.type) {
            for (let i = 0; i < this.priceLinesDateAndCount.length; i++) {
                this.priceLinesDateAndCount[i].prices.splice(index, 1);
            }
        }

        this._calculateValue();
    }

    _findMatchingColumn(min, max) {
        for (let i = 0; i < this.counts.length; i++) {

            if (this.counts[i].min === min && this.counts[i].max === max) {
                return i;
            }
        }

        return null;
    }

    _calculateValue() {
        const value = {type: this.type, pricePerCount: this.pricePerCount};
        const priceLines = [];

        console.log('TYPE: ', this.type)

        switch (this.type) {
            case 'single':

                let fixed = 'both';

                const purchasePrice = this.formService.getValue().purchasePrice;
                const retailPrice = this.formService.getValue().retailPrice;

                if (purchasePrice && !retailPrice) {
                    fixed = 'purchasePrice';
                } else if (!purchasePrice && retailPrice) {
                    fixed = 'retailPrice';
                }

                value.purchasePrice = purchasePrice;
                value.retailPrice = retailPrice;
                value.taxPercentage = this.taxPercentage;
                value.type = this.type;
                value.fixed = fixed;
                break;

            case 'date':
            case 'dateAndWeekDay':
                value.manualFactor = this.manualFactor;
                value.taxPercentage = this.taxPercentage;
                value.priceLines = this.priceLines.map(({from, to, price, weekDays, seasonText}) => ({
                    from, to, weekDays, seasonText,
                    [this.enterPurchasePrices ? 'purchasePrice' : 'retailPrice']: {
                        amount: PriceSetInput._convertToModel(price, this.currency),
                        currency: this.currency
                    }
                }));
                break;

            case 'dateAndCount':
                for (let {from, to, prices, seasonText, vacancies} of this.priceLinesDateAndCount) {
                    for (let i = 0; i < this.counts.length; i++) {

                        const line = {
                            from, to, seasonText, vacancies,
                            countFrom: this.counts[i].min,
                            countTo: this.counts[i].max,
                        };

                        line[this.enterPurchasePrices ? 'purchasePrice' : 'retailPrice'] = {
                            amount: PriceSetInput._convertToModel(prices[i] || 0, this.currency),
                            currency: this.currency
                        };

                        priceLines.push(line);
                    }
                }

                value.priceLines = priceLines;
                value.taxPercentage = this.taxPercentage;
                value.manualFactor = this.manualFactor;
                break;

            case 'count':
                for (let {min, max, price} of this.counts) {

                    const line = {
                        countFrom: min,
                        countTo: max,
                        vacancies: this.vacancies,
                    };

                    line[this.enterPurchasePrices ? 'purchasePrice' : 'retailPrice'] = {
                        amount: PriceSetInput._convertToModel(price || 0, this.currency),
                        currency: this.currency
                    };

                    priceLines.push(line);
                }

                value.priceLines = priceLines;
                value.manualFactor = this.manualFactor;
                break;

            default:
                this.value = null;
                return;
        }

        if (this.type === 'date' || this.type === 'dateAndCount' || this.type === 'count' || this.type === 'dateAndWeekDay') {
            value.fixed = this.enterPurchasePrices ? 'purchasePrice' : 'retailPrice';
        }

        if (this.seasonTexts.length) {
            value.priceLines = (value.priceLines ?? [])
                .map(({seasonText, ...rest}) => ({
                    ...rest,
                    seasonText: this.seasonTexts.includes(seasonText) ? seasonText : this.seasonTexts[0]
                }))
        }

        this.value = value;
    }

    addPriceLine() {
        if (this.type === 'date' || this.type === 'dateAndWeekDay') {
            this.priceLines.push({prices: []});
        } else {
            this.priceLinesDateAndCount.push({prices: []});
        }

        this._calculateValue();
    }

    copyPriceLine(index) {
        if (this.type === 'date' || this.type === 'dateAndWeekDay') {
            this.priceLines.splice(index, 0, _.cloneDeep(this.priceLines[index]));
        } else {
            this.priceLinesDateAndCount.splice(index, 0, _.cloneDeep(this.priceLinesDateAndCount[index]));
        }

        this._calculateValue();
    }

    removePriceLine(index) {

        if (this.type === 'date' || this.type === 'dateAndWeekDay') {
            this.priceLines.splice(index, 1);
        } else {
            this.priceLinesDateAndCount.splice(index, 1);
        }

        this._calculateValue();
    }
}
